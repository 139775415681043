/*<<<<<<<<<<<<< CSS TABLE OF CONTENTS  >>>>>>>>>>>>*/

/*<<<<<<  FONTS & COLORS */
/*<<<<<<  GENERAL */
/*<<<<<<  STATE(1) - START SCREEN */
/*<<<<<<  STATE(2) - CAMERA */
/*<<<<<<  STATE(3) - COUNTDOWN */
/*<<<<<<  STATE(4) - LOADING */
/*<<<<<<  STATE(5) - RESULT */
/*<<<<<<  FOOTER */
/*<<<<<<  MEDIA QUERIES - IPAD */

/*<<<<<<<<<<<<<  FONTS & COLORS  >>>>>>>>>>>>*/
@font-face {
	font-family: "MightySouly";
	/*src:  url("https://assets-private.eventfinity.co/materials/2803541/original/MightySouly.wofff");*/
	src: url(/src/assets/fonts/MightySouly.woff) format("woff");
}

:root {
	--regular-font: "MightySouly", sans-serif;
	--base-fs: 20px;
}

/*<<<<<<<<<<<<<  GENERAL  >>>>>>>>>>>>*/
html {
	font-size: var(--base-fs);
}

body {
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.06) 37%, rgba(0, 0, 0, 0) 100%);
	background-image: url(/src/assets/original_size/krustyKrabStartBackground.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

body.v2 {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.06) 37%, rgba(0, 0, 0, 0) 100%);
}

.hidden {
	visibility: hidden !important;
	opacity: 0 !important;
	z-index: 0;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.App {
	width: 100%;
	height: 100%;
}

/*<<<<<<<<<<<<<  STATE(1) - START SCREEN  >>>>>>>>>>>>*/
.startScreen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 15;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.06) 37%, rgba(0, 0, 0, 0) 100%);
	background-size: 100% auto;
	background-repeat: no-repeat;
}

img.startLogos {
	width: 100% !important;
	height: auto !important;
	position: absolute;
	top: 50px;
	left: 12px;
}

img.touch {
	width: 100%;
	height: auto;
	max-width: 200px;
	margin-bottom: 15vh;
}

/*<<<<<<<<<<<<<  STATE(2) - CAMERA  >>>>>>>>>>>>*/
.camera {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-image: url(/src/assets/original_size/krustyKrabCameraBackground.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

.videoWrapper {
	position: absolute;
	z-index: 1;
	outline: none;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}

video {
	outline: none;
	border-radius: 20px;
	border: 5px solid #fff;
	width: 355px;
	min-height: 460px;
}

.btnPhoto {
	width: 100%;
	height: auto;
	max-width: 250px;
	position: absolute;
	bottom: 8vh;
}

/*<<<<<<<<<<<<<  STATE(3) - COUNTDOWN  >>>>>>>>>>>>*/
.countdown {
	position: absolute;
	width: 100%;
	height: 100%;
	background: transparent;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.countdown img.count {
	height: 30vh;
	width: auto;
	margin-bottom: 20vh;
}

/*<<<<<<<<<<<<<  STATE(4) - LOADING  >>>>>>>>>>>>*/
.loading {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-image: url(/src/assets/original_size/krustyKrabCameraBackground.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

.loadingText {
	color: #ffffff;
	font-family: var(--regular-font);
	font-size: 2.75rem;
	width: 100%;
	text-align: center;
	padding: 20px;
}

.loadingContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/*<<<<<<<<<<<<<  STATE(5) - RESULT  >>>>>>>>>>>>*/
.result {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-image: url(/src/assets/original_size/krustyKrabCameraBackground.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

.result canvas {
	width: 100%;
	height: auto;
	display: block;
	max-width: 95%;
	border-radius: 20px;
	margin-top: -75px;
}

.finalText {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 4px 20.6px 0px rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	width: 350px;
	height: 450px;
	padding: 20px;
	flex-direction: column;
	align-items: center;
}

img.qrText,
img.btnRestart {
	width: 100%;
	height: auto;
	max-width: 250px;
	margin: 15px auto;
}

img.btnRestart {
	position: absolute;
	bottom: 15px;
}

.finalText svg {
	border-radius: 20px;
	border: 1px solid #000;
	padding: 15px;
}

/*<<<<<<<<<<<<<  FOOTER  >>>>>>>>>>>>*/
p.footer {
	color: #fff;
	text-transform: uppercase;
	font-size: 3vw;
	font-family: var(--regular-font);
	padding: 0;
	width: 100%;
	text-align: center;
	margin-bottom: 0.5rem;
	position: absolute;
	bottom: 0;
	left: 0;
}

p.footer a,
p.footer a:visited,
p.footer a:hover,
p.footer a:active {
	color: #fff;
	text-transform: uppercase;
	font-size: 3vw;
	font-family: var(--regular-font);
	padding: 0;
	text-decoration: none !important;
}

/*<<<<<<<<<<<<<  MEDIA QUERIES - IPAD  >>>>>>>>>>>>*/
@media (min-width: 896px) {
	video {
		width: 700px;
		top: 45%;
	}
	.finalText {
		width: 800px;
		height: 900px;
	}

	img.qrText {
		max-width: 600px;
		margin-top: 30px;
	}

	.finalText svg {
		transform: scale(2);
		margin-top: 125px;
		background: white;
	}

	img.btnRestart {
		max-width: 350px;
		bottom: 30px;
	}
}
